<template>
	<div class="about">
		<v-container class="pa-0" fluid>
			<v-row align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8" class="pt-0">
					<v-img
							contain
							src="../assets/thumb.jpg"
							width="100%"
					/>
				</v-col>
			</v-row>

			<v-card
					outlined
					tile
					class="border-none shop-card">
				<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-card-subtitle class="font-weight-bold">
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled

							>
								エリア
							</v-chip>
							東京・恵比寿
							<br>
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled
							>
								ジャンル
							</v-chip>
							ラーメン割烹
						</v-card-subtitle>
						<div class="shop-title">
							<v-card-title>
								GENEI.WAGAN
							</v-card-title>
							<v-card-subtitle>
								〒150-0012 東京都渋谷区広尾1-10-10<br>
								広尾駅徒歩8分
							</v-card-subtitle>
							<v-btn
									tile
									depressed
									color="white"
									class="icon-map"
							>
								<i class="fas fa-map-marker-alt mr-1"></i>Google Map
							</v-btn>
						</div>
						<v-card-subtitle>
							<span style="margin-right:1em;">ランチ</span><span
								class="ml-2 title">&yen;4,500〜</span>(税サ別 / 人)<br>
							ディナー<span class="ml-2 title">&yen;4,500〜</span>(税サ別 / 人)
						</v-card-subtitle>
						<v-divider></v-divider>
						<v-card-text>
							<p>
								福岡・薬院に本店を持つ「体験型」ラーメン店が発祥。自家製の醤油から作り出されるスープは、上品な中にも旨味が凝縮されており、様々なスープへの変化にも万能に対応し、お客様を飽きさせることがありません。最高級イベリコ豚の出汁しゃぶしゃぶ鍋も、より多くの皆様にゆったり楽しんでいただけるよう、新しいコース料理の体験をご提案致します。</p>
						</v-card-text>
					</v-col>
				</v-row>

				<v-row align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8">
				<v-row class="grey lighten-3">
					<v-col sm="6" md="6" lg="5" xl="5" class="pa-0">
						<v-img
								contain
								src="../assets/01.jpg"
								width="100%"
						/>
					</v-col>
					<v-col sm="6" md="6" lg="7" xl="7" color="black">
						<v-card-subtitle>
						『お家でGENEI』あご出汁薫和牛味噌もつ鍋セット<br>
						¥8,500（税抜）
						</v-card-subtitle>
						<v-card-text>
							要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字
						</v-card-text>
						<v-card-text>
						<v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
							<v-spacer></v-spacer>
							<v-btn
								color="#e60044"
								dark
								depressed
								tile
							>
							カートに追加する
							</v-btn>
						</v-col>

					</v-card-text>

					</v-col>
				</v-row>
				</v-col>
				</v-row>

				<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-row class="grey lighten-3">
							<v-col sm="6" md="6" lg="5" xl="5" class="pa-0">
								<v-img
										contain
										src="../assets/02.jpg"
										width="100%"
								/>
							</v-col>
							<v-col sm="6" md="6" lg="7" xl="7" color="black">
								<v-card-subtitle>
									『お家でGENEI』最上級イベリコ豚の白湯&麻辣火鍋セット<br>
									¥8,500（税抜）
								</v-card-subtitle>
								<v-card-text>
									要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字
								</v-card-text>
								<v-card-text>
									<v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
										<v-spacer></v-spacer>
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
										>
											カートに追加する
										</v-btn>
									</v-col>

								</v-card-text>

							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-row class="grey lighten-3">
							<v-col sm="6" md="6" lg="5" xl="5" class="pa-0">
								<v-img
										contain
										src="../assets/03.jpg"
										width="100%"
								/>
							</v-col>
							<v-col sm="6" md="6" lg="7" xl="7" color="black">
								<v-card-subtitle>
									『お家でGENEI』潮薫醤油ラーメン<br>
									¥8,500（税抜）
								</v-card-subtitle>
								<v-card-text>
									要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字
								</v-card-text>
								<v-card-text>
									<v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
										<v-spacer></v-spacer>
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
										>
											カートに追加する
										</v-btn>
									</v-col>

								</v-card-text>

							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="8">
						<v-row class="grey lighten-3">
							<v-col sm="6" md="6" lg="5" xl="5" class="pa-0">
								<v-img
										contain
										src="../assets/04.jpg"
										width="100%"
								/>
							</v-col>
							<v-col sm="6" md="6" lg="7" xl="7" color="black">
								<v-card-subtitle>
									『お家でGENEI』麻辣ナッツ担々麺<br>
									¥8,500（税抜）
								</v-card-subtitle>
								<v-card-text>
									要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字 要約テキスト50文字
								</v-card-text>
								<v-card-text>
									<v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
										<v-spacer></v-spacer>
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
										>
											カートに追加する
										</v-btn>
									</v-col>

								</v-card-text>

							</v-col>
						</v-row>
					</v-col>
				</v-row>

			</v-card>








		</v-container>
	</div>
</template>

<script>
	import products from "@/api/products.js";

	export default {
		data() {
			return {
				drawer: false,

				courses: ['Aコース', 'Bコース', 'Cコース', 'Dコース'],
				peoples: ['1人', '2人', '3人', '4人'],
				shops: ['LA BETTOLA da Ochiai', 'ARMONICO', 'AU GAMIN DE TOKIO',
					'Comptoir Missago', 'GENEI.WAGAN', 'Margotto e Baciare', '幸せ三昧', '慈華', '鈴なり'],
				reserveTime: ['11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
				// ご注文プランの選択
				planDialog01: false,
				planDialog02: false,
				planDialog03: false,
				planDialog04: false,
				planDialog05: false,
				picker: new Date().toISOString().substr(0, 10),
				picker2: null,
			}
		},
		methods: {
			async setInfo(info) {
				this.item = info.data[0];
				console.log(this.item);
			}
		},
		mounted() {
			products.getFeed(this.setInfo, '1');
		}
	}
</script>
<style scoped>
	@import "~@/styles/style.css";

	.v-text-field > .v-input__control > .v-input__slot::before{
		border-color: rgba(0, 0, 0, 0);
	}
	.btn-reserve button{
		width:100%;
		padding:32px;
	}
	.border-none {
		border:none!Important;
	}
	.card-pa0 {
		padding:0!important;
	}
	.icon-map i {
		color:#e60044;
		font-size:24px;
	}
	.v-text-field--filled {
		border-radius:0;
	}
	.select-menu {
		border-radius:0!important;
		height: 56px;
		overflow: hidden;
	}
	.btn-reserve button,
	.btn-reserve a
	{
		height: auto!important;
		padding-top: 24px!important;
		padding-bottom: 24px!Important;
		font-weight:bold;
	}
	.btn-register{
		text-align:center;
	}
	.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
		color: rgba(0, 0, 0, 0.87);
	}

</style>
